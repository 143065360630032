














import { Component, Mixins } from 'vue-property-decorator'

import { AllowedHAlignment } from '../../../contexts'
import { SizeMap } from '../../../dsl/composables'

import { AbstractModuleUi } from '../../abstract/ui'
import { UseAlignment } from '../../mixins/UseAlignment'

import { HeadingModule } from '../Heading.contracts'

/**
 * Container component for the `HeadingModuleUi`.
 *
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<HeadingModuleUi>({ name: 'HeadingModuleUi' })
export class HeadingModuleUi extends Mixins<AbstractModuleUi<HeadingModule>,
  UseAlignment>(AbstractModuleUi, UseAlignment) {
  protected align = this.data.content.align ?? AllowedHAlignment.Center
  protected alignDesktop = this.data.content.alignDesktop

  public get displaySize (): string {
    if (typeof this.content.displayLevel !== 'string') {
      return SizeMap.Medium
    }

    return this.content.displayLevel
  }
}

export default HeadingModuleUi
